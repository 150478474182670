var THREEx = THREEx || {};
THREEx.RendererStats = function () {
  var e = document.createElement('div');
  e.style.cssText = 'width:80px;opacity:0.9;cursor:pointer';
  var t = document.createElement('div');
  (t.style.cssText =
    'padding:0 0 3px 3px;text-align:left;background-color:#200;'),
    e.appendChild(t);
  var n = document.createElement('div');
  (n.style.cssText =
    'color:#f00;font-family:Helvetica,Arial,sans-serif;font-size:9px;font-weight:bold;line-height:15px'),
    (n.innerHTML = 'WebGLRenderer'),
    t.appendChild(n);
  for (var o = [], r = 0; r < 9; r++)
    (o[r] = document.createElement('div')),
      (o[r].style.cssText =
        'color:#f00;background-color:#311;font-family:Helvetica,Arial,sans-serif;font-size:9px;font-weight:bold;line-height:15px'),
      t.appendChild(o[r]),
      (o[r].innerHTML = '-');
  var i = Date.now();
  return {
    domElement: e,
    update: function (e) {
      if (!(Date.now() - i < 1e3 / 30)) {
        i = Date.now();
        var t = 0;
        (o[t++].textContent = '== Memory ===='),
          (o[t++].textContent = 'Programs: ' + e.info.programs.length),
          (o[t++].textContent = 'Geometries: ' + e.info.memory.geometries),
          (o[t++].textContent = 'Textures: ' + e.info.memory.textures),
          (o[t++].textContent = '== Render ====='),
          (o[t++].textContent = 'Calls: ' + e.info.render.calls),
          (o[t++].textContent = 'Vertices: ' + e.info.render.vertices),
          (o[t++].textContent = 'Faces: ' + e.info.render.faces),
          (o[t++].textContent = 'Points: ' + e.info.render.points);
      }
    },
  };
};
